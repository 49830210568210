import axios from "axios";

// const API_DOMAIN = 'http://localhost:8000/';
// const API_BASE_URL = "https://novalyabackend.novalya.com/admin/api";
const API_DOMAIN = "https://novalyabackend.novalya.com/";
const API_BASE_URL = `${API_DOMAIN}admin/api`;


function updateAuthorizationHeader() {
  const token = localStorage.getItem("token");
  axios.defaults.headers.common["authorization"] = "Bearer " + token;
}

axios.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response;
  },
  error => {
    // If the error response status is 401, handle logout
    if (error.response && error.response.status === 401 ) {
      localStorage.removeItem("auth-token");
      localStorage.removeItem("token");
      if( window.location.pathname !== '/login'){
      window.location.href = "/login"

      }
    }
    return Promise.reject(error); // Otherwise, return the error to be handled
  }
);

//react admin routes

export function authUserData(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/userdata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateAccountSetting(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/adminaccountsettings"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getAccountSetting(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(`${API_BASE_URL}${"/getadminaccountsettings"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//residuelreport

export function getAdminLogs(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/adminlogs"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function ResiduelReportApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/residuelreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// coupon code select field data
export function GetRankApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/getrank"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// coupon manage table select field data
export function fetchautocouponApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/getautocoupon"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update withdrawal get api getsettingsdata

export function getsettingsdata(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/getsettingsdata"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getlimitsdata(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}/getlimitsdata`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function binarypointsreportApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/binarypointsreport"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function previuosmonthrecordApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/previuosmonthrecord"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//update withdrawal get api getsettingsdata

export function DeleteautocouponApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/deleteautocoupon"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function BinarytreeApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/binarytree"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function loginuser(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/loginfromadmin"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function GetautocouponApi(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/autocoupon"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatesettingdata(data, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/updatesettingsdata"}`, data)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatelimitsdata(data, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/updatelimitsdata"}`, data)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function dashboard(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/dashboard"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// dashboard transactions
export function dashboardtransactions(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/lasttransactions"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//donation summary
export function donationsummary(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/donationsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//create user session
export function createusersession(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/createusersession"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//block user
export function BlockUserApi(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/blockuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//unblock user
export function UnBlockuserApi(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/unblockuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//payout summary

export function payoutsummary(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/payoutsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getnovafreeranksApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getnovafreeranks"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//dashboarddata

export function DashboardDataApi(callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${"/dashboarddata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deployuser(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/deployuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function autocouponApi(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/autocoupon"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function signleUserBinaryDetailsApi(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/singleuserbinarydetails"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatetransactionpassword(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/updatetransactionpassword"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function addnews(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/addnews"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deploymanualpaymentuser(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/deploymanualpaymentuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function rejectmanualpaymentuser(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/rejectmanualpaymentuser"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// update unilevel api
export function updateunilevel(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateunilevel", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// update unilevel api
export function updatepool(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updatepool", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Get Users List
export function getusers(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getuserslist", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//get manual payments data//

export function getmanualpayments(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getmanualpayments", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Delete Pending user

export function deleteuser(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/deleteuser", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Create Admin
export function createadmin(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/createadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update Admin
export function updateminiadmin(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateminiadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Get Admins

export function getadmins(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getadminslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//getminiadmin

export function GetMiniAdminApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getminiadmin"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//getunilevels
export function getunilevel(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getunilevel"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//getpool
export function getpool(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getpool"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// DeleteMiniAdminApi

export function DeleteMiniAdminApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/deleteminiadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// get getuserslist api

export function getuserslist(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getuserslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Single user data
export function singleUserApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/singleuserdata", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get User allowed routes
export function userAllowedRoutes(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .get(API_BASE_URL + "/userallowedroutes")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get User Balance
export function userbalance(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/userbalance", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get Manage all news

export function getnews(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getnews"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get KYC Pending

export function getkycreport(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/kycreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get payout details update report

export function GetPayoutDetailsUpdateReport(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/payoutdetailsupdatereport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get payout details update report

export function SubScriptionReportApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/subscriptionreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// affilate report

export function AffilateReportApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/affilatereport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// payout report

export function PayoutReportApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/payoutreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// get users list

export function GetUsersListApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getuserslist"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function UserBinaryDetails(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/userbinarydetails"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// rank report
export function RankReportApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/rankreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// rank report
export function trialusers(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/trialusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function NovaRankReportApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/novarankreport"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// pending payout

export function PendingPayoutApi(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/pendingpayout"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//Remove  Mini Admin
export function removeadmin(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/removeadmin", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approvesinglepayout
export function ApproveSinglePayoutApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/approvesinglepayout", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approveall payout
export function ApproveAllPayoutApi(callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/approveallpayout", "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//processing payout
export function payoutSendToProcessing(callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/processingallpayout", "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//get all processing payout
export function getAllProcessingPayout(callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getallprocessingpayout", "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//approved payout
export function ApprovedPayoutApi(callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/approvedpayout", "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//updatepassword
export function UpdatepasswordApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updatepassword", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update transaction password
export function UpdatetransactionPasswordApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updatetransactionpassword", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//update user password
export function UpdateUserPasswordApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateuserpassword", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approvekyc
export function ApprovekycApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/approvekyc", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//approve payout update request
export function ApprovePayoutUpdateRequestApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/approvepayoutupdaterequest", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//rejectkyc
export function RejectkycApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/rejectkyc", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//reject payout update request
export function RejectPayoutUpdateRequestApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/rejectpayoutupdaterequest", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// delete news api

export function deletenews(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/deletenews", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
// Update the User Balance

export function UpdateCurrentBalance(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updatecurrentbalance", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateUserDetailsApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateuserdetails", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function toggleChatApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/chatToggle", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateUserPlanLimitsApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateuserplanlimits", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateUserLevelCommissionApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateuserunilevel", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Update Sponsor
export function updatesponsor(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updatesponsor", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//Unlock user
export function unlockuser(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/unlockuser", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function addDeductPoints(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/adddeductbinarypoints", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function managecolumnsApi(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/managecolumns", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function registerUser(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/user/register", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authenticate(service, callback, errorCallback) {
  axios
    .post(API_BASE_URL, service)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function roidata(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/user/roidata"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function lasttransactions(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/lastweektransactions"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function referralusers(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/user/referralusers"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investmentreport(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/user/depositsummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function referralbonusreport(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/referralbonussummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function unilevelbonusreport(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/unilevelbonussummary"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getunilevelreports(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getunilevelreports"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getpoolreports(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getpoolreports"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function binarypointsreportfordavina(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/binarypointsreportfordavina"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getpooldistributionreports(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getpooldistributionreports"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getlevelbonusdedcuted(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getlevelbonusdedcuted"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function faqdata(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getfaqs"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function transaction(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/transaction", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function invitation(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/sendinvitationlink", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function adminwallet(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getadminwallet"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getHierarchyData(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/gethierarchy"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getBinaryTreeData(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/binarytree", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getSingleUserBinaryTreeData(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/singleuserbinarytreedata", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfileData(formdata, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/updateprofiledata"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function verifyUserEmailManual(params, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/verifyuseremailmanual"}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfilePicture(formdata, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/updateprofilepicture"}`, formdata)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateProfilePassword(
  oldpassword,
  newpassword,
  callback,
  errorCallback
) {
  axios
    .post(`${API_BASE_URL}${"/updatepassword"}`, {
      oldpassword,
      newpassword,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function requestPasswordReset(email, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/forgetpassword"}`, {
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function investandeearning(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/monthlyinvestandeearning"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getusersales(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getusersales", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getnotifications(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/getnotifications"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatenotificationstatus(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/updatenotificationstatus"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function passwordReset(email, password, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/resetpassword"}`, {
      email,
      password,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function validateEmailToken(token, email, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/validateemailtoken"}`, {
      token,
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function verifyemailaccount(token, email, callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/verifyemailaccount"}`, {
      token,
      email,
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function postRequest(url, params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(`${API_BASE_URL}${url}`, params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

//gray label

export function getGrayLabelListing(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"s/reseller/get-listing"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function createGrayLabel(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "s/reseller/add-new", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function deleteGrayLabelUser(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "s/reseller/delete", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateGrayLabelStatus(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "s/reseller/update-status", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getSingleData(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "s/reseller/get-details", { id: params.user_id })
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateGrayLabel(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "s/reseller/update-profile", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
//  this route get data from userdata table and add in users_limits table with user id it may b one time use
// export function fbInstaLimit(params, callback, errorCallback) {
//   axios
//     .post(API_BASE_URL + "/fbinstalimit", params)
//     .then((response) => {
//       if (callback) {
//         callback(response);
//       }
//     })
//     .catch((error) => {
//       if (errorCallback) {
//         errorCallback(error);
//       }
//     });
// }

export function userLimit(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/userlimit", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function updateUserLimits(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateuserlimits", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updatepayoutdetails(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updatepayoutdetails", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}
export function poolreport(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/pool-report", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getpendingcomission(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getpendingcomission", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function getunpaidcomission(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/getunpaidcomission", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function updateconnection(params, callback, errorCallback) {
  axios
    .post(API_BASE_URL + "/updateconnection", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

// Get Current Months Pending Payment

export function GetCurrentMonthPay(callback, errorCallback, signal) {
  axios
    .post(`${API_BASE_URL}/current-month-total-pending`, "", { signal }) // passing signal to allow cancellation
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Request aborted');
      } else if (errorCallback) {
        errorCallback(error);
      }
    });
}


// Getting Pending Payment of Users

export function GetPendingPay(callback, errorCallback) {
  axios
    .post(`${API_BASE_URL}${"/pending-pay-of-all-users"}`, "")
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function syncOldMessages(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_DOMAIN + "all/messages/api/process-old-messages", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function affilateTab(params, callback, errorCallback) {
  updateAuthorizationHeader();
  axios
    .post(API_BASE_URL + "/adminSetAffiliate", params)
    .then((response) => {
      if (callback) {
        callback(response);
      }
    })
    .catch((error) => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}