import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { GetPendingPay } from "backendServices/ApiCalls";
import { Grid, Typography, Card, CircularProgress, CardContent } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div"; // Assuming Div is a custom styled div component

const PendingPay = () => {
    const { t } = useTranslation();
    const [payoutDetailReport, setPayoutDetailReport] = useState([]);
    const [totalUSDPayout, setTotalUSDPayout] = useState(0);
    const [totalEURPayout, setTotalEURPayout] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });

    // Fetch payout details from the API
    const GetPayoutDetailsReport = () => {
        GetPendingPay(
            (response) => {
                const {data, total_usd_pay,total_eur_pay} = response?.data;
                const processedData = data?.map((row, index) => ({
                    ...row,
                    id: index + 1, // Ensure each row has a unique id
                }));
                setTotalUSDPayout(total_usd_pay);
                setTotalEURPayout(total_eur_pay);
                // Set user data and totals into state
                setPayoutDetailReport(()=>processedData);
                setIsLoading(false);
            },
            (error) => {
                setalertData({
                    show: true,
                    message: "Failed to fetch data",
                    variant: "error",
                });
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        GetPayoutDetailsReport();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "#",
            width: 80,
        },
        {
            field: "firstname",
            headerName: "First Name",
            width: 150,
            editable: false,
        },
        {
            field: "lastname",
            headerName: "Last Name",
            width: 150,
            editable: false,
        },
        {
            field: "username",
            headerName: "Username",
            width: 150,
            editable: false,
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
            editable: false,
        },
        {
            field: "current_balance_usd_payout",
            headerName: "USD Payout",
            width: 150,
            editable: false,
            valueFormatter: (params) => params?.value?.toFixed(2), // Format as 2 decimal places
        },
        {
            field: "current_balance_eur_payout",
            headerName: "EUR Payout",
            width: 150,
            editable: false,
            valueFormatter: (params) => params?.value?.toFixed(2), // Format as 2 decimal places
        },
    ];

    const gridDesign = {
        "& .MuiDataGrid-toolbarContainer": {
            "& .MuiButton-text": {
                fontSize: "13px !important",
            },
            "& .MuiBadge-badge": {
                backgroundColor: "#074682",
            },
            "& .MuiInput-root": {
                borderRadius: 2,
                paddingLeft: 2,
                overflow: "hidden",
            },
        },
    };

    return (
        <>
            {isLoading ? (
                <Div
                    sx={{
                        maxWidth: 345,
                        margin: '20% auto',
                        padding: '20px',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
              
                    <CircularProgress size={40} sx={{ mb: 2 }} />
                    
                </Div>
            ) : (
                <Grid
                    container
                    fullWidth
                    sm={12}
                    xs={12}
                    p={2}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item sm={12} xs={12}>
                    <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Card
                sx={{
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "16px",
                  width: "300px",
                  textAlign: "start",
                }}
              >
                <Typography variant="h6" sx={{ color: "#074682", fontWeight: "bold" }}>
                  {t("Total Amounts")}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  <span style={{ fontWeight: 600 }}>{t("Total USD Payout")}: </span>
                  {totalUSDPayout?.toFixed(2)} USD
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <span style={{ fontWeight: 600 }}>{t("Total EUR Payout")}: </span>
                  {totalEURPayout?.toFixed(2)} EUR
                </Typography>
              </Card>
            </Box>
                        <JumboDemoCard
                            title={t("pages.title.PendingPay")}
                            wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
                        >
                            {alertData.show && (
                                <SweetAlert alertData={alertData} setalertData={setalertData} />
                            )}
                            <Box sx={{ height: 500, width: "100%" }}>
                                <DataGrid
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 6 } },
                                    }}
                                    rows={payoutDetailReport}
                                    columns={columns}
                                    getRowId={(row) => row.id} // Ensure that each row has a unique id
                                    slots={{ toolbar: GridToolbar }}
                                    sx={gridDesign}
                                    pageSizeOptions={[6, 12, 18, 24, 30]}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                />
                            </Box>
                        </JumboDemoCard>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default PendingPay;
